
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'add-hexagon'
  | 'arrow-east'
  | 'arrow-left'
  | 'arrow-right'
  | 'chevron-down-marketing'
  | 'chevron-down'
  | 'close'
  | 'download'
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'logo'
  | 'magnifyingglass'
  | 'newspaper'
  | 'paperplane'
  | 'play'
  | 'search'
  | 'threads'
  | 'twitter'
  | 'x_formerly_twitter'
  | 'youtube'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.Aawy8NpQGG73fODB9rr3ap_UOAC3Bo5jj7oh6Fz-_mg.svg"}

export type RuntimeOptions = {
  ariaHidden: boolean
}

export const runtimeOptions: RuntimeOptions = {"ariaHidden":false}
